import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
export default function preLoader() {
	return (
		<>
			<div className="pre-loader-img bottompage-loader">
				<img className="loader-boicon" src={LoaderIcon} alt="loader" />
				<img src={LoaderSvg} alt="loader" />
			</div>
		</>
	)
}