import { memo } from 'react'
import { useDrag } from 'react-dnd'
const style = {
  border: '1px dashed gray',
  /*backgroundColor: 'white', pointer-events:none   */ 
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
  float: 'left',
  width: '100%'
}
export const Box = memo(function Box({ name, type, isDropped }) {
 // console.log('rr',name, type, isDropped);
  const [{ opacity,border }, drag] = useDrag(
    () => ({
      type,
      item: { name },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
        border:monitor.isDragging() ? "1px solid #00AEEF" : " ",
      }),
    }),
    [name, type],
  )


  return (
    <div ref={drag}  data-testid="box"  style={{ "pointerEvents":(isDropped)?"none":"" ,"border":(drag)?border:""}}> 
      {isDropped ? <div style={{"pointerEvents":"none","opacity":"0.3"}}>{name}</div> : name}
    </div>
  )
})
