import React, { useRef, useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
//import 'font-awesome/css/font-awesome.min.css';
import "./assets/css/custom.css";
import Logo from "./assets/images/logologin.png";
import FooterLogo from "./assets/images/logologinFooter.png";
/*import Login from './component/authentication/login';
import BrandDashboard from './component/dashboard/index';
import PageNotFound from './component/error/404';
import ListOfInvites from './component/dashboard/inviteList';*/
import BrandNavigation from "./component/layouts/BrandDashboardHeader";
import BrandAside from "./component/layouts/BrandAside";
import BrandFooter from "./component/layouts/BrandFooter";
import StockPage from "./component/stock/index";
import ImsList from "./component/stock/imsList";
import UserList from "./component/users/userList";
const Login = lazy(() => import('./component/authentication/login'))
const InviteBrand = lazy(() => import('./component/dashboard/index'))
const InviteBrandList = lazy(() => import('./component/dashboard/inviteList'))
const imageData = {
  "logo": Logo,
  "footer_logo": FooterLogo
};
function PrivateRoute({ children }) {
  const auth = localStorage.getItem('token');
  return auth ? children : <Navigate to="/" />;
}
function PublicRoute({ children }) {
  const auth = localStorage.getItem('token');
  return auth ? <Navigate to="/invite-brand" />: children ;
}
function App() {
  const ref = useRef();
  const [showMaster, setShowMaster] = useState(false)
  let token = localStorage.getItem("token")
  useEffect(() => {
    if(token){
      setShowMaster(true)
    }
  },[token])
  const headerFooterFun = (type) => {
    if(type === 'hide'){
      setShowMaster(false)
    }else if(type === 'show'){
      setShowMaster(true)
    
    }
  }
  return (
    <BrowserRouter>
      <div className="wrapper" ref={ref} id="myscreen">
        {(showMaster)?<>
          <BrandNavigation masterLayoutFun={headerFooterFun}/>
          <BrandAside /></>:""}
          <Suspense fallback={<div>..</div>}>
          <Routes>
          <Route path="/" element={<PublicRoute><Login imageData={imageData} masterLayoutFun={headerFooterFun}/></PublicRoute>} exact={true} />
          <Route path="/invite-brand" element={<PrivateRoute><InviteBrand imageData={imageData}/></PrivateRoute>} exact={true} />        
          <Route path="/invite-brand/list" element={<PrivateRoute><InviteBrandList imageData={imageData}/></PrivateRoute>} exact={true} /> 
          <Route path="/stock" element={<PrivateRoute><StockPage imageData={imageData}/></PrivateRoute>} exact={true} /> 
          <Route path="/stock/ims-list" element={<PrivateRoute><ImsList imageData={imageData}/></PrivateRoute>} exact={true} /> 
          <Route path="/user-list" element={<PrivateRoute><UserList imageData={imageData}/></PrivateRoute>} exact={true} /> 
          

        </Routes>
        </Suspense>
        {(showMaster)?<>
        <BrandFooter logo={imageData.footer_logo} /></>:""}
      </div>
    </BrowserRouter>
  );
}

export default App;
