import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LeftLogo from './../../assets/images/logoletf.png';
import MenuIsOn from './../../assets/images/dashboardicon.png';
import MenuIsOff from './../../assets/images/dashoboardoff.png';
import BrandOn from './../../assets/images/brandon.png';
import BrandOff from './../../assets/images/brandoff.png';
import UsersOn from './../../assets/images/userson.png';
import UsersOff from './../../assets/images/usersoff.png';
import DspsOn from './../../assets/images/dspson.png';
import DspsOff from './../../assets/images/dspsoff.png';
import EntriesOn from './../../assets/images/entrieson.png';
import EntriesOff from './../../assets/images/entriesoff.png';
import ImssOn from './../../assets/images/imsson.png';
import ImssOff from './../../assets/images/imssoff.png';
import GroupsOn from './../../assets/images/groupson.png';
import GroupsOff from './../../assets/images/groupsoff.png';
import SettingOn from './../../assets/images/settingon.png';
import UserMain from './../../assets/images/user-main-face.png';
import AccountOn from './../../assets/images/accounton.png';
import AccountOff from './../../assets/images/accountoff.png';

function BrandAside(props) {


	const onMouseOver = (e) => {
		if (e.currentTarget.getElementsByTagName("a")[0].classList.contains('active')) {
			e.currentTarget.classList.remove('remove-uper-border')
			e.currentTarget.classList.remove('remove-bottom-border')
		}
		if (e.currentTarget.nextSibling) {
			if (e.currentTarget.nextSibling.getElementsByTagName("a")[0].classList.contains('active')) {
				e.currentTarget.nextSibling.classList.add('remove-uper-border')
			} else {
				let liTag = document.getElementById('__navbar').getElementsByTagName("li");
				var elemsLenght = liTag.length;
				for (var i = 0; i < elemsLenght; ++i) {
					if (liTag[i].classList.contains('remove-uper-border')) {
						liTag[i].classList.remove('remove-uper-border')
					}
				}
			}
		}
		if (e.currentTarget.previousSibling) {
			if (e.currentTarget.previousSibling.getElementsByTagName("a")[0].classList.contains('active')) {
				e.currentTarget.previousSibling.classList.add('remove-bottom-border')
			} else {
				let liTag = document.getElementById('__navbar').getElementsByTagName("li");
				var elemsLenght = liTag.length;
				for (var i = 0; i < elemsLenght; ++i) {
					if (liTag[i].classList.contains('remove-bottom-border')) {
						liTag[i].classList.remove('remove-bottom-border')
					}
				}
			}
		}

	}
	const onMouseLeave = (e) => {
		let liTag = document.getElementById('__navbar').getElementsByTagName("li");
		var elemsLenght = liTag.length;
		for (var i = 0; i < elemsLenght; ++i) {
			if (liTag[i].classList.contains('remove-bottom-border') || liTag[i].classList.contains('remove-uper-border')) {
				liTag[i].classList.remove('remove-bottom-border')
				liTag[i].classList.remove('remove-uper-border')
			}
		}
	}
	return (
		<>
			<aside className="main-sidebar">
				<div className="user-profile-menu">
					<div className="profile-images">
						<img alt="" className="main-profile" src={UserMain} />
					</div>
					<div className="profile-user-name">
						{localStorage.getItem('name')}
					</div>
					<div className="profile-user-email">
						{localStorage.getItem('email')}
					</div>
					<div className="profile-user-desigantion">
						Head of all
					</div>
				</div>


				<div className="menusections">
					<div className="navitems-area" id="__navbar">
						<ul onMouseLeave={onMouseLeave}>
							<li onMouseOver={onMouseOver} className="fullarea menuisonstate">
								<Link to="#" onClick={(e) => e.preventDefault()}>
									<div className="menuimages">
										<div className="menuimgbg">
											<img alt="MenuIsOn" className="menuison" src={MenuIsOn} />
											<img alt="MenuIsOff" className="menuisoff" src={MenuIsOff} />
										</div>
										Dashboard
									</div>
								</Link>
							</li>



							<li onMouseOver={onMouseOver} className="fullarea menuisonstate">
								<NavLink to="/invite-brand">
									<div className="menuimages">
										<div className="menuimgbg">
											<img alt="BrandOn" className="menuison" src={BrandOn} />
											<img alt="BrandOff" className="menuisoff" src={BrandOff} />
										</div>
										Brands
									</div>
								</NavLink>
							</li>

							<li onMouseOver={onMouseOver} className="fullarea menuisonstate">
								<Link to="#" onClick={(e) => e.preventDefault()}>
									<div className="menuimages">
										<div className="menuimgbg">
											<img alt="GroupsOn" className="menuison" src={GroupsOn} />
											<img alt="GroupsOff" className="menuisoff" src={GroupsOff} />
										</div>
										Groups
									</div>
								</Link>
							</li>

							<li onMouseOver={onMouseOver} className="fullarea menuisonstate">
								<Link to="#" onClick={(e) => e.preventDefault()}>
									<div className="menuimages">
										<div className="menuimgbg">
											<img alt="DspsOn" className="menuison" src={DspsOn} />
											<img alt="DspsOff" className="menuisoff" src={DspsOff} />
										</div>
										DSPs
									</div>
								</Link>
							</li>

							<li onMouseOver={onMouseOver} className="fullarea menuisonstate">
								<NavLink to="/stock">
									<div className="menuimages">
										<div className="menuimgbg">
											<img alt="ImssOn" className="menuison" src={ImssOn} />
											<img alt="ImssOff" className="menuisoff" src={ImssOff} />
										</div>
										IMSs
									</div>
								</NavLink>
							</li>

							<li onMouseOver={onMouseOver} className="fullarea menuisonstate">
								<NavLink to="/user-list">
									<div className="menuimages">
										<div className="menuimgbg">
											<img alt="UsersOn" className="menuison" src={UsersOn} />
											<img alt="UsersOff" className="menuisoff" src={UsersOff} />
										</div>
										Users
									</div>
								</NavLink>
							</li>

							<li onMouseOver={onMouseOver} className="fullarea menuisonstate">
								<Link to="#" onClick={(e) => e.preventDefault()}>
									<div className="menuimages">
										<div className="menuimgbg">
											<img alt="EntriesOn" className="menuison" src={EntriesOn} />
											<img alt="EntriesOff" className="menuisoff" src={EntriesOff} />
										</div>
										Entries
									</div>
								</Link>
							</li>

							<li onMouseOver={onMouseOver} className="fullarea menuisonstate">
								<Link to="#" onClick={(e) => e.preventDefault()}>
									<div className="menuimages">
										<div className="menuimgbg">
											<img alt="AccountOn" className="menuison" src={AccountOn} />
											<img alt="AccountOff" className="menuisoff" src={AccountOff} />
										</div>
										Account
									</div>
								</Link>
							</li>

						</ul>
					</div>

					<div className="navitems-area bottom-section">
						<ul>
							<li className="halfarea">
								<Link to="#" onClick={(e) => e.preventDefault()}>
									<div className="menuimages">
										<img alt="setting-on" src={SettingOn} />
									</div>
									Settings
								</Link>
							</li>
						</ul>
					</div>


				</div>
			</aside>
		</>
	);
}
export default BrandAside;