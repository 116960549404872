import React, { useRef, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { read, utils } from 'xlsx';
import { Box } from './Box.js'
import { Dustbin } from './Dustbin.js'
import DataTable, { createTheme } from 'react-data-table-component';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import axios from 'axios';
import { API_URL, API_KEY } from './../../config/constant.js';
import './../../assets/css/checkbox.css'
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorWarning from './../../assets/images/error-icon.png';
import errorSuccess from './../../assets/images/icons8-check-64.png'
import Select from 'react-select';
import moment from 'moment';
import PreLoader from './../loader/preLoader';
import SubmitLoader from './../loader/submitLoader';


toast.configure();
createTheme('solarized', {
    text: {
        primary: '#fff',
        secondary: '#fff',
    },
    background: {
        default: '#10141B',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#333F50',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');


function Dashboard(props) {
    document.title = 'BOstageA - IMS/Stock';
    const ref = useRef();
    const fileRef = useRef();
    const [elementData, setElementData] = useState("");
    const [fileName, setFileName] = useState("");
    const [progressBar, setProgressBar] = useState(0);
    const [didectedFields, setDidectedFields] = useState([]);
    const [temD, setTemD] = useState([]);
    const [resPop, setResPop] = useState(false);
    const [draggedData, setDraggedData] = useState([]);
    const [filePreviewButtonDisable, setFilePreviewButtonDisable] = useState(true)
    const [saveButtonDisable, setSaveButtonDisablee] = useState(true)

    const [nameRequired, setNameRequired] = useState(false)
    const [isProductTypeRequired, setIsProductTypeRequired] = useState(false)
    const [descRequired, setDescRequired] = useState(false)
    const [shortDescRequired, setShortDescRequired] = useState(false)
    const [productList, setProductList] = useState([]);
    const [submitLoader, setSubmitLoader] = useState(false);

    useEffect(() => {
        setElementData(ref.current);

    }, []);

    const handleValidation = (obj) => {
        let formIsValid = true;
        if (typeof obj.Store_ID == 'undefined') {

            setNameRequired(true)
            formIsValid = false;
        }
        if (typeof obj.GTIN == 'undefined') {

            setIsProductTypeRequired(true)
            formIsValid = false;
        }
        if (typeof obj.Stock == 'undefined') {

            setDescRequired(true)
            formIsValid = false;
        }
        if (typeof obj.Prices == 'undefined') {

            setShortDescRequired(true)
            formIsValid = false;
        }

        return formIsValid;
    }

    const readImporterFile = (e) => {
        // setPreviousPosData([])
        const files = e.target.files;
        if (files.length) {
            setTemD([])

            const file = files[0];
            setFileName(file.name);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const didectedHeaders = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                        header: 1,
                        defval: '',
                        blankrows: false
                    });
                    const didectedData = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                        defval: '-',
                        blankrows: true
                    });
                    let customHeader = [];
                    didectedHeaders[0].map((value, index) => {
                        customHeader.push({ "name": value, "type": "text" });
                    });
                    setDidectedFields(customHeader);
                    setProductList(didectedData)
                    setFilePreviewButtonDisable(false)
                    //setAppPreviewButtonDisable(true)
                    setSaveButtonDisablee(false)
                }
            }
        }
    }

    let ket = [];
    if (draggedData?.length) {
        let dataValidation = JSON.parse(draggedData);
        dataValidation?.forEach(r => {
            let key = Object.keys(r);
            ket.push(key);
        })
    }


    const fileList = (index, item, label) => {
        //setTemD(temD => [...temD, { leftItemIndex: index, boxName: label, itemName: item.name }])
    }

    useEffect(() => {
        temD.map((_v, _i) => {
            let inss = didectedFields.map(e => e.name).indexOf(_v?.itemName);
            _v.leftItemIndex = inss;
            return _v
        })
        setDataS(temD)
    }, [temD])
    const rmvData = (label) => {
        // console.log(label);
        const dat = dataS.filter((e) => {
            if (e.boxName != label) {
                return e;
            }
        });
        setDataS(dat);
    }


    const fullScreenCheck = (e) => {
        let fulls = localStorage.getItem('fullscreen');
        if (fulls == 'full') {
            document.body.requestFullscreen();
        }
    }

    var percentage = progressBar + '%';

    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfPage, setNumberOfPage] = useState(30);
    const PaginationComponent = (e) => {
        let totalRecord = e.rowCount
        let rowsPerPage = e.rowsPerPage
        let noOfPage = Math.ceil(totalRecord / rowsPerPage);
        let dataPagination = [];
        for (let i = 1; i <= noOfPage; i++) {
            dataPagination.push(i)
        }

    }

    const arrData = ((inx, val) => {
        //console.log("arrData", val);
        let ob = cunstObj(inx, val);
        arr.push(ob);
        setDraggedData(JSON.stringify(arr));
        setResPop(true);
    })

    const cunstObj = ((inx, val) => {
        //console.log("val",typeof val);
        let value = val?.toString()?.split(' ')?.join('_');
        value = value?.split('/')?.join('_');
        let indx = inx?.split(' ')?.join('_');
        indx = indx?.split('/')?.join('_');
        return { [indx]: value }
    })

    let arr = [];

    const clear = ((ele, lbl) => {
        setResPop(false);
        let item = ele;
        let index = droppedBoxNames.indexOf(item);
        droppedBoxNames.splice(index, 1);

        setDroppedBoxNames(
            update(droppedBoxNames, item ? { $push: [""] } : { $push: [] }),
        )
        // console.log("previousArray - "+ JSON.stringify(arr));
        let lblRm = lbl.split(' ').join('_');
        lblRm = lblRm.split('/').join('_');

        let inx = arr.findIndex(ar => ar[lblRm]);
        arr.splice(inx, 1);
        //console.log("AfterDelArray - "+ JSON.stringify(arr));

        rmvData(lbl);



    });

    var data = [];
    productList.map((value, index) => {
        // console.log("list", productList)
        Object.keys(value).forEach((key) => {
            var replacedKey = key.replace(/ /g, '_').replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_');
            if (key !== replacedKey) {
                value[replacedKey] = (typeof value[key] !== "undefined") ? value[key] : "-";
                if (typeof value[key] !== "undefined") {
                    delete value[key];
                }
            }
        });
        data.push(value);
    })

    let obj = {}
    const importData = () => {
        if (draggedData.length) {
            let jsonObjectArray = JSON.parse(draggedData);
            jsonObjectArray.map((v, i) => {
                let key = Object.keys(v);
                let value = Object.values(v);
                obj[key] = value[0]
            })
        }
        // console.log("testt dhdsd",obj);
        if (handleValidation(obj)) {

            let formData = [];
            productList.map((value, index) => {

                // console.log("list value dataa", value);
                let postData = {
                    "storeID": (obj.Store_ID) ? value[obj.Store_ID].toString() : "0",
                    "GTINbarcode": (obj.GTIN) ? value[obj.GTIN].toString() : "0",
                    "stock": (obj.Stock) ? value[obj.Stock] : 0,
                    "price": (obj.Prices) ? value[obj.Prices] : 0,

                }
                formData.push(postData);
            })
            let formJsonData = {
                "postData": formData
            }

            setSaveButtonDisablee(true);
            setSubmitLoader(true);
            axios.post(API_URL + 'stock-price-insert?code=' + API_KEY, formJsonData, {
            //axios.post('http://localhost:7071/api/stock-price-insert', formJsonData, {
                onUploadProgress: progressEvent => {
                    let percentComplete = progressEvent.loaded / progressEvent.total
                    percentComplete = parseInt(percentComplete * 100);
                    setProgressBar(percentComplete);
                }
            }).then((res) => {
                fileRef.current.value = null;
                setSaveButtonDisablee(false)
                if (res.data.status == 200) {
                    setDraggedData([])
                    setProductList([]);
                    setTemD([])
                    setDidectedFields([]);
                    setFileName("");
    

                    setProgressBar(0);
                    setNameRequired(false);
                    setIsProductTypeRequired(false);
                    setDescRequired(false);
                    setShortDescRequired(false);
                    setSaveButtonDisablee(true);


                    toast.success(res.data.message, {
                        theme: "dark",
                        icon: ({ theme, type }) => <img src={errorSuccess} />
                    });
                } else {
                    toast.warning(res.data.message, {
                        theme: "dark",
                        icon: ({ theme, type }) => <img src={errorWarning} />
                    });
                }
                setDustbins(update(dustbins, { [0]: { lastDroppedItem: { $set: null, } } }))
                setDustbins1(update(dustbins1, { [0]: { lastDroppedItem: { $set: null, } } }))
                setDustbins2(update(dustbins2, { [0]: { lastDroppedItem: { $set: null, } } }))
                setProductTypeDustbin(update(productTypeDustbin, { [0]: { lastDroppedItem: { $set: null, } } }))
                setSubmitLoader(false);
                setDroppedBoxNames([])
            })
        }
    }

    const [dataS, setDataS] = useState([])

    const columns = [];
    didectedFields.map((res) => {
        let selector = (((res.name).toString())?.split(' ').join('_')).replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
        let dataHeader = {
            name: res.name,
            selector: selector,
            sortable: true,
        }
        columns.push(dataHeader)
    })

    useEffect(() => {
        setDidectedFields(didectedFields);
    }, [didectedFields])

    const [droppedBoxNames, setDroppedBoxNames] = useState([]);
    const isDropped = (boxName) => {
        return droppedBoxNames.indexOf(boxName) > -1
    }

    const [dustbins, setDustbins] = useState([
        { accepts: ['text'], lastDroppedItem: null, label: "Store ID" }
    ]);

    const [productTypeDustbin, setProductTypeDustbin] = useState([
        { accepts: ['text'], lastDroppedItem: null, label: "GTIN" }
    ]);


    const [dustbins1, setDustbins1] = useState([
        { accepts: ['text'], lastDroppedItem: null, label: "Stock" }
    ]);

    const [dustbins2, setDustbins2] = useState([
        { accepts: ['text'], lastDroppedItem: null, label: "Prices" }
    ]);


    const handleDrop = useCallback(
        (index, item, label) => {

            const { name } = item
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            )
            fileList(index, item, label);
            //console.log("trtd",item,name);

            setDustbins(
                update(dustbins, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedBoxNames, dustbins]
    )

    const handleProductType = useCallback(
        (index, item, label) => {
            const { name } = item
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            )
            fileList(index, item, label);
            setProductTypeDustbin(
                update(productTypeDustbin, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedBoxNames, productTypeDustbin],
    )

    const handleDrop1 = useCallback(
        (index, item, label) => {
            const { name } = item
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            )
            fileList(index, item, label);
            setDustbins1(
                update(dustbins1, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedBoxNames, dustbins1],
    )

    const handleDrop2 = useCallback(
        (index, item, label) => {
            const { name } = item
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            )
            fileList(index, item, label);
            setDustbins2(
                update(dustbins2, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedBoxNames, dustbins2],
    )

    return (
        <>
            <div className="conten-wrapper">
                <div className="controlbar">
                    <div className="productsearch leftsidetr notactive">
                        <Link className="button-not-active" data-widget="search" to="#" role="button" >
                            <span className="btn btn-success "><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                            <span className="searchprodicttext">Data</span>
                        </Link>
                    </div>


                    <div className="centerbtn">

                        <Link to="#" className="btn btn-success active">STOCK & PRICES</Link>

                    </div>

                    <div className="rightcontrolserch">
                        <div className="productsearch rightareas">
                            <Link data-widget="search" to="#" role="button">
                                <span className="btn btn-success active"><i className="fa-thin fa-database"></i></span>
                                <span className="searchprodicttext">Data</span>
                            </Link>
                        </div>
                        <div className="productsearch rightareas">
                            <Link data-widget="search" to="/stock/ims-list" role="button" >
                                <span className="btn btn-success "><i className="fa-thin fa-list"></i></span>
                                <span className="searchprodicttext">List</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="products-listingarea filtersarea images-importer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                {/*<div className="custom-breadcrumb">
                          <StepCombination cdata={props.compData}/>
                           </div>*/}
                                <div className="product-importer-filebtn">
                                    <div className="form-group">
                                        <input type="file" ref={fileRef} name="fileImport" id="fileImport" className="inputfile inputfile-6" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => { readImporterFile(e); fullScreenCheck(e); }} />
                                        <label htmlFor="fileImport"><span>{fileName}</span>

                                        </label>
                                        <label htmlFor="fileImport" className={(fileName) ? "stromngclass active" : "stromngclass active"}>Upload File</label>

                                    </div>
                                </div>


                                <div className="filtersection">
                                    <span className="btnsets">
                                        <span className="customeset gobtn">
                                            {/* <button type="submit" className="btn btn-success" onClick={handleShow} disabled={(filePreviewButtonDisable) ? 'disabled' : ''}><i className="fa-thin fa-table"></i> FILE PREVIEW</button> */}
                                            <button type="submit" className="btn btn-success" disabled='disabled'><i className="fa-thin fa-table"></i> FILE PREVIEW</button>
                                        </span>

                                        <span className="customeset gobtn">
                                            {/* <button type="submit" className="btn btn-success save-control" onClick={SaveDistribution} disabled={(saveButtonDisable) ? 'disabled' : ''}> {(!productList.length) ? (<i className="fa-thin fa-save"></i>) : (saveButtonDisable) ? (<i className="fas fa-spinner fa-spin"></i>) : (<i className="fa-thin fa-save"></i>)} {(productList.length) ? 'SAVE ' + productList.length + ' ITEMS' : 'SAVE'}</button> */}
                                            <button type="submit" className="btn btn-success" onClick={importData} disabled={(saveButtonDisable) ? 'disabled' : ''} ><i className="fa-thin fa-save"></i> SAVE</button>
                                        </span>

                                    </span>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="product-infosection">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="productinfo productDivimporter">
                                    <div className="cardsection prdoctMargin">
                                        <div className="card-header">
                                            <h4 className="card-title">STOCK & PRICES FILE
                                                {/* <div className="file-include-urls">
                                                    <div className="form-group padd-top">
                                                        <label className="container-checkbox">
                                                            <input type="checkbox" />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <span className="file-text"> File includes URLs for product images <i className="fas fa-info-circle"></i>
                                                        </span>
                                                    </div>
                                                </div> */}
                                                <span className="formcautions">
                                                    <ul>
                                                        <li><span className="makesqaure mandatorythis"></span>mandatory fields</li>
                                                        <li><span className="makesqaure lockedthis"></span>locked fields</li>
                                                    </ul></span>
                                            </h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="info-products-form">
                                                <form>
                                                    <div className="headpart">
                                                        <div className="row"></div>
                                                    </div>
                                                    <div className="showprogress">
                                                        <div className="progress">
                                                            <div className="progress-bar" role="progressbar" aria-valuenow={progressBar} aria-valuemin="0" aria-valuemax="100" style={{ width: percentage }}>
                                                                <span className="sr-only">{progressBar}0% Complete</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <DndProvider backend={HTML5Backend}>
                                                        <div className="formdivisions">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <div className="fieldsarea">
                                                                        <div className="form-group">
                                                                            <label for="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Fields detected </label>
                                                                        </div>
                                                                        <div className="fieldfouds">
                                                                            {didectedFields?.map(({ name, type }, index) => {
                                                                                return (<Box
                                                                                    name={name}
                                                                                    type={type}
                                                                                    isDropped={isDropped(name)}
                                                                                    key={index}
                                                                                />)

                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="products-col">
                                                                        <h4>STOCK & PRICES</h4>
                                                                        <div className="form-group">
                                                                            <label for="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Store ID</label>
                                                                            {dustbins.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                <Dustbin
                                                                                    accept={accepts}
                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                    onDrop={(item) => handleDrop(index, item, label)}
                                                                                    key={index}
                                                                                    label={label}
                                                                                    clear={clear}
                                                                                    arrData={arrData}
                                                                                    valids={nameRequired}
                                                                                />
                                                                            ))}

                                                                        </div>
                                                                    </div>
                                                                    <div className="products-col">
                                                                        <div className="form-group">
                                                                            <label for="tobrand">
                                                                                <i className="fas fa-info-circle" aria-hidden="true"></i> GTIN</label>
                                                                            {productTypeDustbin.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                <Dustbin
                                                                                    accept={accepts}
                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                    onDrop={(item) => handleProductType(index, item, label)}
                                                                                    key={index}
                                                                                    label={label}
                                                                                    clear={clear}
                                                                                    arrData={arrData}
                                                                                    valids={isProductTypeRequired}
                                                                                />
                                                                            ))}

                                                                        </div>
                                                                    </div>
                                                                    <div className="products-col">
                                                                        <div className="form-group">
                                                                            <label for="tobrand">
                                                                                <i className="fas fa-info-circle" aria-hidden="true"></i> Stock</label>
                                                                            {dustbins1.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                <Dustbin
                                                                                    accept={accepts}
                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                    onDrop={(item) => handleDrop1(index, item, label)}
                                                                                    key={index}
                                                                                    label={label}
                                                                                    clear={clear}
                                                                                    arrData={arrData}
                                                                                    valids={descRequired}
                                                                                />
                                                                            ))}

                                                                        </div>
                                                                    </div>
                                                                    <div className="products-col">
                                                                        <div className="form-group">
                                                                            <label for="tobrand">
                                                                                <i className="fas fa-info-circle" aria-hidden="true"></i> Prices</label>
                                                                            {dustbins2.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                <Dustbin
                                                                                    accept={accepts}
                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                    onDrop={(item) => handleDrop2(index, item, label)}
                                                                                    key={index}
                                                                                    label={label}
                                                                                    clear={clear}
                                                                                    arrData={arrData}
                                                                                    valids={shortDescRequired}
                                                                                />
                                                                            ))}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </DndProvider>

                                                </form>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
            {submitLoader ? <SubmitLoader /> : ""}
        </>
    );
}
export default Dashboard;